import {
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { UserAddress } from '@infrab4a/connect';

type CPFValidation = { invalidCPF: true } | null;

type EmailDomain = {
  name: string;
  domains: string[];
};

const emailDomains: EmailDomain[] = [
  {
    name: 'gmail',
    domains: ['gmail.com']
  },
  {
    name: 'hotmail',
    domains: ['hotmail.com', 'hotmail.com.br']
  },
  {
    name: 'outlook',
    domains: ['outlook.com', 'outlook.com.br']
  },
  {
    name: 'yahoo',
    domains: ['yahoo.com', 'yahoo.com.br']
  },
  {
    name: 'uol',
    domains: ['uol.com', 'uol.com.br']
  },
  {
    name: 'icloud',
    domains: ['icloud.com', 'icloud.com.br']
  }
];

export class ValidatorsUtil {
  static cpfValidator = (cpfCtrl: FormControl): CPFValidation => {
    const sanitizedCPF = (cpfCtrl.value ?? '').replace(/\D/g, '');
    if (sanitizedCPF.length !== 11) return { invalidCPF: true };
    const sanitizedCPFDigits: number[] = sanitizedCPF
      .split('')
      .map((x: string) => Number(x));
    if (
      sanitizedCPFDigits.filter((x) => x === sanitizedCPFDigits[0]).length ===
      11
    )
      return { invalidCPF: true };
    const { 9: givenTenth, 10: givenEleventh } = sanitizedCPFDigits;
    const firstNine = sanitizedCPFDigits.splice(
      0,
      sanitizedCPFDigits.length - 2
    );
    const foundTenth = findCPFTenth(firstNine);
    if (givenTenth !== foundTenth) return { invalidCPF: true };
    const firstTen = [...firstNine, foundTenth];
    const foundEleventh = findCPFEleventh(firstTen);
    if (givenEleventh !== foundEleventh) return { invalidCPF: true };
    return null;
  };

  static phoneValidator: ValidatorFn = (phoneCtrl) => {
    return /^\(\d{2}\)(?:\s*)\d{5}-\d{4}$/.test(phoneCtrl.value) ||
      /^\(\d{2}\)(?:\s*)\d{9}$/.test(phoneCtrl.value) ||
      /^\d{11}$/.test(phoneCtrl.value)
      ? null
      : { 'invalid-phone': true };
  };

  static acceptedAgreement = (fc: FormControl): ValidationErrors => {
    return fc.value !== true ? { 'agreement-not-accepted': true } : null;
  };

  static validatePasswordConfirmation = (
    passwordGroup: FormGroup
  ): ValidationErrors => {
    const password = passwordGroup.get('a').value;
    const confirmation = passwordGroup.get('b').value;
    return password !== confirmation ? { mismatch: true } : null;
  };

  static creditCardValidator: ValidatorFn = (creditCardCtrl) => {
    if (typeof creditCardCtrl.value !== 'string')
      return { 'invalid-credit-card': true };
    if (!/^\d{16}$/.test(creditCardCtrl.value))
      return { 'invalid-credit-card': true };
    return null;
  };

  static creditCardCVV: ValidatorFn = (cvvCtrl) => {
    if (typeof cvvCtrl.value !== 'string') return { 'invalid-cvv': true };
    if (!/^\d{3}$/.test(cvvCtrl.value)) return { 'invalid-cvv': true };
    return null;
  };

  static expirationDate: ValidatorFn = (expirationDateCtrl) => {
    if (!expirationDateCtrl.value) return null;
    const year = parseInt(expirationDateCtrl.value.slice(-2));
    const month = parseInt(expirationDateCtrl.value.slice(0, 2));
    const currentMonth = new Date(Date.now()).getMonth() + 1;
    const currentYear = new Date(Date.now()).getFullYear();
    const monthValid = month >= 1 && month <= 12;
    const valid =
      2000 + year === currentYear
        ? currentMonth <= month
        : 2000 + year >= currentYear && monthValid;

    if (!valid) return { 'invalid-expiration-date': true };

    return {};
  };

  static emailValidator: ValidatorFn = (emailCtrl) => {
    if (!emailCtrl.value.match(/(.+)@(.+)\.(.+)/))
      return { 'invalid-email': true };
    return null;
  };

  static fullNameValidator: ValidatorFn = (nameCtrl) => {
    if (!nameCtrl.value) return null;
    const splitted: string[] = nameCtrl.value.split(' ');
    if (!splitted.length || splitted.length <= 1)
      return { 'invalid-full-name': true };
    return null;
  };

  static cepValidator: ValidatorFn = (cepCtrl) => {
    const sanitized = (cepCtrl.value ?? '').replace(/\D/g, '');
    if (sanitized.length !== 8) return { 'invalid-cep': true };
    return null;
  };

  static noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  static setErrorOrDisable(control: FormControl, error: any): void {
    if (error !== null) {
      control.setErrors(error);
      control.markAsTouched();
      control.markAsDirty();
      control.enable();
    } else {
      control.disable();
      control.setErrors(null);
    }
  }

  static domainsRulesEmailValidator: ValidatorFn = (emailCtrl) => {
    if (!emailCtrl.value) return;
    const email = emailCtrl.value;

    if (email.split('@').length > 2) return { invalid_at_character: true };

    const domain = email.split('@')[1];
    const username = email.split('@')[0];

    if (!username.length) return { invalid_no_character: true };

    if (emailDomains[0].domains.includes(domain)) {
      //gmail
      if (!username[0].match(/([a-z])|([A-Z])|([0-9])/)) {
        return { gmail_invalid_first_character: true };
      } else if (username.replaceAll('.', '').match(/[\W_]/)) {
        return { gmail_invalid_special_character: true };
      } else if (!username.match(/[a-zA-Z0-9]$/gm)) {
        return { gmail_invalid_last_character: true };
      } else if (username.length > 7 ? !username.match(/[a-zA-Z]/) : false) {
        return { gmail_invalid_allnumber_character: true };
      } else if (username.match(/\.\./)) {
        return { gmail_invalid_two_dots: true };
      } else if (username.length < 6 || username.length > 30) {
        return { gmail_invalid_username_length: true };
      }
    } else if (
      emailDomains[1].domains.includes(domain) ||
      emailDomains[2].domains.includes(domain)
    ) {
      //hotmail e outlook
      if (!username[0].match(/([a-z])|([A-Z])/)) {
        return { hotlook_invalid_first_character: true };
      } else if (
        username
          .replaceAll('.', '')
          .replaceAll('_', '')
          .replaceAll('-', '')
          .match(/[\W]/)
      ) {
        return { hotlook_invalid_special_character: true };
      } else if (!username.match(/[a-zA-Z0-9_-]$/gm)) {
        return { hotlook_invalid_last_character: true };
      } else if (username.match(/\.\./)) {
        return { hotlook_invalid_two_dots: true };
      }
    } else if (emailDomains[3].domains.includes(domain)) {
      //yahoo
      if (!username[0].match(/([a-z])|([A-Z])/)) {
        return { yahoo_invalid_first_character: true };
      } else if (
        username.replaceAll('.', '').replaceAll('_', '').match(/[\W]/)
      ) {
        return { yahoo_invalid_special_character: true };
      } else if (!username.match(/[a-zA-Z0-9]$/gm)) {
        return { yahoo_invalid_last_character: true };
      } else if (username.match(/\.\./) || username.match(/__/)) {
        return { yahoo_invalid_two_dots: true };
      } else if (username.length < 5 || username.length > 19) {
        return { yahoo_invalid_username_length: true };
      }
    } else if (emailDomains[4].domains.includes(domain)) {
      //uol
      if (!username[0].match(/([a-z])|([A-Z])|([0-9])/)) {
        return { uol_invalid_first_character: true };
      } else if (
        username.replaceAll('.', '').replaceAll('-', '').match(/[\W_]/)
      ) {
        return { uol_invalid_special_character: true };
      } else if (!username.match(/[a-zA-Z0-9]$/gm)) {
        return { uol_invalid_last_character: true };
      } else if (username.match(/\.\./) || username.match(/--/)) {
        return { uol_invalid_two_dots: true };
      } else if (username.length > 32) {
        return { uol_invalid_username_length: true };
      }
    } else if (emailDomains[5].domains.includes(domain)) {
      //icloud
      if (username.replaceAll('.', '').replaceAll('_', '').match(/[\W]/)) {
        return { icloud_invalid_special_character: true };
      } else if (username.length < 3 || username.length > 20) {
        return { icloud_invalid_username_length: true };
      }
    }
  };

  static emailDomainValidator: ValidatorFn = (emailCtrl) => {
    const email = emailCtrl.value;
    if (!email) return null;
    if (!email.match(/(.+)@(.+)\.(.+)/)) {
      return { 'invalid-email': true };
    }
    const [, fullDomain] = email.split('@');
    const domain = fullDomain.split('.')[0];

    const domainToValidate = emailDomains.find((d) => domain.includes(d.name));
    if (domainToValidate && !domainToValidate.domains.includes(fullDomain)) {
      return { 'invalid-email-domain': true };
    }
    return null;
  };

  static passwordValidator: ValidatorFn = (passCtrl) => {
    const specialCharRegex: RegExp = /[^A-z\s\d][\\^]?/;
    const upperCharRegex: RegExp = /(?=.*[A-Z])/;
    const lowerCharRegex: RegExp = /(?=.*[a-z])/;
    const whiteSpaceRegex: RegExp = /\s/;

    let errors = {};

    if (passCtrl.value && !specialCharRegex.test(passCtrl.value)) {
      errors['invalidSpecials'] = true;
    }

    if (passCtrl.value && !upperCharRegex.test(passCtrl.value)) {
      errors['invalidUpperCase'] = true;
    }

    if (passCtrl.value && !lowerCharRegex.test(passCtrl.value)) {
      errors['invalidLowerCase'] = true;
    }

    if (passCtrl.value && whiteSpaceRegex.test(passCtrl.value)) {
      errors['whiteSpace'] = true;
    }

    return errors;
  };
}

const cpfMagicNumbersFirst = [10, 9, 8, 7, 6, 5, 4, 3, 2];
const cpfMagicNumbersSecond = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2];

const getCPFDigit = (sum: number) => {
  const rawOutput = (sum * 10) % 11;
  if (rawOutput === 10 || rawOutput === 11) return 0;
  return rawOutput;
};

const reducerOfMagicNumbers =
  (list: number[]) => (acc: number, v: number, k: number) => {
    return acc + v * list[k];
  };

const findCPFTenth = (firstNine: number[]) => {
  const sum = firstNine.reduce(reducerOfMagicNumbers(cpfMagicNumbersFirst), 0);
  return getCPFDigit(sum);
};

const findCPFEleventh = (firstTen: number[]) => {
  const sum = firstTen.reduce(reducerOfMagicNumbers(cpfMagicNumbersSecond), 0);
  return getCPFDigit(sum);
};

export const validateAddresses = (addreses: UserAddress[]) => {
  return addreses.filter(
    (addr) =>
      addr.street != '' &&
      addr.city != '' &&
      addr.state != '' &&
      addr.number != '' &&
      addr.district != '' &&
      addr.zip != '' &&
      addr.recipient != ''
  );
};
